<template>
  <div v-show="this.$store.state.wallet.connected" class="Mint">
    <div>
      <table>
        <tr>
          <td>Total Minted</td>
          <td>{{ totalMinted }} / 10000</td>
        </tr>
        <tr>
          <td>Total Burned</td>
          <td>{{ totalBurned }}</td>
        </tr>
        <tr>
          <td>Total Staked</td>
          <td>{{ totalStaked }}</td>
        </tr>
        <tr>
          <td>Total Unstaked</td>
          <td>{{ totalSupply - totalStaked }}</td>
        </tr>
        <tr>
          <td>Syringe Minted</td>
          <td>{{ syringeMinted }}</td>
        </tr>
        <tr>
          <td>Syringe Burned</td>
          <td>{{ syringeBurned }}</td>
        </tr>
      </table>
    </div>
    <!-- <div v-if="totalMinted < 10000">
      <div>Mint price per etholvant: 0.024 ETH</div>
      <input
        v-model="numToMint"
        type="range"
        id="mint-num"
        name="mint-num"
        min="1"
        max="14"
      />
      <div>{{ numToMint }} - {{ (numToMint * 24) / 1000 }} ETH</div>
      <div id="mint-button">
        <button type="button" @click="mint">Mint</button>
        <div v-if="isMintLoading" class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
      </div>
    </div> -->
    <div>We're sold out. Please buy from secondary market.</div>
    <hr />
    <div>
      <li>
        <a
          @click.stop="viewSection = 'ethols'"
          href="javascript:void(0);"
          :class="{ black: viewSection == 'ethols' }"
          >Ethols</a
        >
      </li>
      <li>
        <a
          @click.stop="viewSection = 'syringes'"
          href="javascript:void(0);"
          :class="{ black: viewSection == 'syringes' }"
          >Syringes</a
        >
      </li>
    </div>
    <div v-show="viewSection == 'ethols'">
      <div v-show="unstakedTokens.length > 0">
        <hr />
        <div>Your unstaked Etholvants</div>
        <button
          @click="stake"
          class="btn"
          :disabled="unstakedSelected.size == 0"
        >
          Stake Selected
        </button>
        <button
          @click="combine"
          class="btn"
          :disabled="unstakedSelected.size == 0"
        >
          Combine Selected
        </button>
        <div v-if="isStakedLoading" class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
        <br />
        <div
          @click="clickUnstaked(tokenId)"
          class="ethol"
          :class="{ selected: unstakedSelected.has(tokenId) }"
          v-for="tokenId in unstakedTokens"
          :key="tokenId"
        >
          <img :src="imgSources[tokenId]" />
          <div class="caption">
            #{{ tokenId }} - {{ numCells[tokenId] }} cells <br />
            {{ growthRate[tokenId] }} cells / 4hr
          </div>
        </div>
      </div>
      <div v-show="stakedTokens.length > 0">
        <hr />
        <div>Your staked Etholvants</div>
        <button
          @click="unstake"
          class="btn"
          :disabled="stakedSelected.size == 0"
        >
          Unstake Selected
        </button>
        <div v-if="isUnstakedLoading" class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
        <br />
        <div
          @click="clickStaked(tokenId)"
          class="ethol"
          :class="{ selected: stakedSelected.has(tokenId) }"
          v-for="tokenId in stakedTokens"
          :key="tokenId"
        >
          <img :src="imgSources[tokenId]" />
          <div class="caption">
            #{{ tokenId }} - {{ numCells[tokenId] }} cells <br />
            {{ growthRate[tokenId] }} cells / 4hr
          </div>
        </div>
      </div>
    </div>
    <div v-show="viewSection == 'syringes'">
      <hr />
      <div>You have {{ ownedSyringes }} Booster Syringes.</div>
      <div v-show="eligibleToMint.length > 0">
        <hr />
        <div>Your Etholvants eligible to mint syringe</div>
        <button
          @click="mintSyringe()"
          class="btn"
          :disabled="selectedToMint.size == 0"
        >
          Mint Selected
        </button>
        <div v-if="isMintBatchLoading" class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
        <br />
        <div
          @click="clickForMint(tokenId)"
          class="ethol"
          :class="{ selected: selectedToMint.has(tokenId) }"
          v-for="tokenId in eligibleToMint"
          :key="tokenId"
        >
          <img :src="imgSources[tokenId]" />
          <div class="caption">
            #{{ tokenId }} - {{ numCells[tokenId] }} cells <br />
            {{ growthRate[tokenId] }} cells / 4hr
          </div>
        </div>
      </div>
      <div v-show="eligibleToInject.length > 0">
        <hr />
        <div>Your Etholvants eligible to inject</div>
        <button
          @click="inject"
          class="btn"
          :disabled="
            eligibleToInject.size == 0 ||
            selectedToInject == null ||
            ownedSyringes == 0
          "
        >
          Inject Selected
        </button>
        <div v-if="isInjectLoading" class="sk-chase">
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
          <div class="sk-chase-dot"></div>
        </div>
        <br />
        <div
          @click="
            selectedToInject = selectedToInject == tokenId ? null : tokenId
          "
          class="ethol"
          :class="{ selected: selectedToInject == tokenId }"
          v-for="tokenId in eligibleToInject"
          :key="tokenId"
        >
          <img :src="imgSources[tokenId]" />
          <div class="caption">
            #{{ tokenId }} - {{ numCells[tokenId] }} cells <br />
            {{ growthRate[tokenId] }} cells / 4hr
          </div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { ethers } from "ethers";
import { draw } from "../drawer";

export default {
  name: "Mint",
  props: {},

  data() {
    return {
      totalMinted: null,
      totalBurned: null,
      totalSupply: null,
      totalStaked: null,
      syringeMinted: null,
      syringeBurned: null,

      numToMint: 1,
      stakedTokens: [],
      unstakedTokens: [],
      imgSources: {},
      numCells: {},
      growthRate: {},
      unstakedSelected: new Set([]),
      stakedSelected: new Set([]),
      isMintLoading: false,
      isStakedLoading: false,
      isUnstakedLoading: false,
      isInjectLoading: false,
      isMintBatchLoading: false,
      viewSection: "ethols",

      ownedSyringes: null,
      eligibleToInject: [],
      eligibleToMint: [],
      selectedToInject: null,
      selectedToMint: new Set([]),
    };
  },

  computed: {
    connected() {
      return this.$store.state.wallet.connected;
    },
  },

  watch: {
    connected: function (value) {
      if (value == true) {
        this.refresh();
      }
    },
  },

  mounted() {
    if (this.$store.state.wallet.contract) {
      this.refresh();
    }
  },

  methods: {
    fetchData() {
      var contract = this.$store.state.wallet.contract;
      contract.initMintCount().then((result) => {
        this.totalMinted = result;
      });
      contract.burnedWithCombine().then((result) => {
        this.totalBurned = result;
      });
      contract.totalStaked().then((result) => {
        this.totalStaked = result;
      });
      contract.totalSupply().then((result) => {
        this.totalSupply = result;
      });

      var syringeContract = this.$store.state.wallet.syringeContract;
      var address = this.$store.state.wallet.address;
      syringeContract.balanceOf(address, 0).then((result) => {
        this.ownedSyringes = result;
      });
      syringeContract.totalMinted().then((result) => {
        this.syringeMinted = result;
      });
      syringeContract.totalBurned().then((result) => {
        this.syringeBurned = result;
      });
    },

    fetchTokens() {
      var contract = this.$store.state.wallet.contract;
      var address = this.$store.state.wallet.address;
      var vm = this;
      contract.getTokensOfOwner(address).then((result) => {
        vm.unstakedTokens = result;
        for (let i in result) {
          this.setTokenData(result[i]);
          this.setSyringeData(result[i]);
        }
      });
      contract.getStakedTokens(address).then((result) => {
        vm.stakedTokens = result;
        for (let i in result) {
          this.setTokenData(result[i]);
          this.setSyringeData(result[i]);
        }
      });
    },

    setSyringeData(etholId) {
      var vm = this;
      this.$store.state.wallet.syringeContract
        .getData(etholId)
        .then((result) => {
          if (result[2] == true) {
            vm.eligibleToInject.push(etholId);
          }
          if (result[3] == true) {
            vm.eligibleToMint.push(etholId);
          }
        });
    },

    refresh() {
      this.eligibleToInject = [];
      this.eligibleToMint = [];
      this.fetchData();
      this.fetchTokens();
      this.unstakedSelected.clear();
      this.stakedSelected.clear();
      this.selectedToInject = null;
      this.selectedToMint.clear();
    },

    clickUnstaked(tokenId) {
      if (this.unstakedSelected.has(tokenId)) {
        this.unstakedSelected.delete(tokenId);
      } else {
        this.unstakedSelected.add(tokenId);
      }
    },

    clickStaked(tokenId) {
      if (this.stakedSelected.has(tokenId)) {
        this.stakedSelected.delete(tokenId);
      } else {
        this.stakedSelected.add(tokenId);
      }
    },

    clickForMint(tokenId) {
      if (this.selectedToMint.has(tokenId)) {
        this.selectedToMint.delete(tokenId);
      } else {
        this.selectedToMint.add(tokenId);
      }
    },

    mint() {
      var contract = this.$store.state.wallet.contract;
      var mintCost = ethers.BigNumber.from("24000000000000000");
      contract
        .mint(this.numToMint, {
          value: mintCost.mul(this.numToMint),
        })
        .then((result) => {
          this.isMintLoading = true;
          // new Promise((r) => setTimeout(r, 2000))
          // .then(() => {
          result
            .wait()
            .then(() => {
              console.log("success mint");
              this.isMintLoading = false;
              this.$notify({
                text: "Minting Successful",
                type: "success",
              });
              this.refresh();
            })
            .catch((err) => {
              this.isMintLoading = false;
              console.log(err);
              this.$notify({
                text: "Minting Failed",
                type: "error",
              });
              // });
            })
            .catch((err) => {
              this.isMintLoading = false;
              console.log(err);
              this.$notify({
                text: "Minting Failed",
                type: "error",
              });
            });
        });
    },

    stake() {
      this.$store.state.wallet.contract
        .stake(Array.from(this.unstakedSelected))
        .then((result) => {
          this.isStakedLoading = true;
          // new Promise((r) => setTimeout(r, 3000))
          // .then(() => {
          result
            .wait()
            .then(() => {
              console.log("success stake");
              this.isStakedLoading = false;
              this.$notify({
                text: "Staking Successful",
                type: "success",
              });
              this.refresh();
            })
            .catch((err) => {
              this.isStakedLoading = false;
              this.$notify({
                text: "Staking Failed",
                type: "error",
              });
              console.log(err);
            });
        })
        .catch((err) => {
          this.isStakedLoading = false;
          this.$notify({
            text: "Staking Failed",
            type: "error",
          });
          console.log(err);
        });
      // });
    },

    combine() {
      this.$store.state.wallet.contract
        .combine(Array.from(this.unstakedSelected))
        .then((result) => {
          this.isStakedLoading = true;
          // new Promise((r) => setTimeout(r, 5000))
          // .then(() => {
          result
            .wait()
            .then(() => {
              console.log("success combine");
              this.isStakedLoading = false;
              this.$notify({
                text: "Combining Successful",
                type: "success",
              });
              this.refresh();
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                text: "Combining Failed",
                type: "error",
              });
              this.isStakedLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            text: "Combining Failed",
            type: "error",
          });
          this.isStakedLoading = false;
        });
      // });
    },

    unstake() {
      this.$store.state.wallet.contract
        .unstake(Array.from(this.stakedSelected), {
          value: ethers.BigNumber.from("10000000000000000"),
        })
        .then((result) => {
          this.isUnstakedLoading = true;
          // new Promise((r) => setTimeout(r, 5000))
          // .then(() => {
          result
            .wait()
            .then(() => {
              console.log("success unstake");
              this.isUnstakedLoading = false;
              this.$notify({
                text: "Unstaking Successful",
                type: "success",
              });
              this.refresh();
            })
            .catch((err) => {
              this.isUnstakedLoading = false;
              this.$notify({
                text: "Unstaking Failed",
                type: "error",
              });
              console.log(err);
            });
        })
        .catch((err) => {
          this.isUnstakedLoading = false;
          this.$notify({
            text: "Unstaking Failed",
            type: "error",
          });
          console.log(err);
        });
      // });
    },

    mintSyringe() {
      this.$store.state.wallet.syringeContract
        .mintBatch(Array.from(this.selectedToMint))
        .then((result) => {
          this.isMintBatchLoading = true;
          // new Promise((r) => setTimeout(r, 3000))
          // .then(() => {
          result
            .wait()
            .then(() => {
              console.log("success mint");
              this.isMintBatchLoading = false;
              this.$notify({
                text: "Mint Successful",
                type: "success",
              });
              this.refresh();
            })
            .catch((err) => {
              this.isMintBatchLoading = false;
              this.$notify({
                text: "Mint Failed",
                type: "error",
              });
              console.log(err);
            });
        })
        .catch((err) => {
          this.isMintBatchLoading = false;
          this.$notify({
            text: "Mint Failed",
            type: "error",
          });
          console.log(err);
        });
      // });
    },

    inject() {
      console.log(this.selectedToInject);
      this.$store.state.wallet.syringeContract
        .inject(this.selectedToInject)
        .then((result) => {
          this.isInjectLoading = true;
          // new Promise((r) => setTimeout(r, 3000))
          // .then(() => {
          result
            .wait()
            .then(() => {
              console.log("success inject");
              this.isInjectLoading = false;
              this.$notify({
                text: "Injection Successful",
                type: "success",
              });
              this.refresh();
            })
            .catch((err) => {
              this.isInjectLoading = false;
              this.$notify({
                text: "Injection Failed",
                type: "error",
              });
              console.log(err);
            });
        })
        .catch((err) => {
          this.isInjectLoading = false;
          this.$notify({
            text: "Injection Failed",
            type: "error",
          });
          console.log(err);
        });
      // });
    },

    setTokenData(tokenId) {
      this.$store.state.wallet.contract.getTokenData(tokenId).then((result) => {
        this.numCells[tokenId] = result[1].toNumber();
        this.growthRate[tokenId] = result[3].toNumber();
        if (this.imgSources[tokenId] == null) {
          this.imgSources[tokenId] = draw(
            result[0],
            result[1].toNumber(),
            result[2].toNumber()
          );
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #999;
  border-radius: 5px;
  height: 1px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  outline: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

#mint-button {
  margin-top: 20px;
}

.ethol img {
  width: 100px;
  height: 100px;
  image-rendering: pixelated;
}

.ethol {
  display: inline-block;
  margin: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  transition: all 0.2s;
}

.ethol:hover {
  background-color: #e2e1e1;
  cursor: pointer;
}

.selected {
  background-color: #d1d1d1;
}

.caption {
  font-size: 12px;
  margin: 5px;
  margin-top: 10px;
  margin-bottom: -5px;
}

.btn {
  margin: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.black {
  color: black;
}
</style>
