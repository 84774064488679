import { ethers } from "ethers";

function setPixel(data, x, y, size) {
    var i = (y * size + x) * 4
    data[i] = 0;
    data[i + 1] = 0;
    data[i + 2] = 0;
    data[i + 3] = 255;

    i = (y * size + size - 1 - x) * 4
    data[i] = 0;
    data[i + 1] = 0;
    data[i + 2] = 0;
    data[i + 3] = 255;
}


function getR(seed, i) {
    let packed = ethers.utils.solidityPack(["bytes32", "uint256"], [seed, i])
    let hash = ethers.utils.keccak256(packed)
    let r = ethers.BigNumber.from(hash).mod(7).toNumber()
    return r
}


export const draw = function (seed, numCells, size) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    canvas.width = size;
    canvas.height = size;

    var imgData = ctx.getImageData(0, 0, size, size);
    var data = imgData.data;

    var x = Math.floor(size / 2)
    var y = x
    var r

    setPixel(data, x, y, size)

    for (var i = 2; i < numCells; i += 2) {
        r = getR(seed, i)
        if (r >= 4)
            x = (x + 1) % size
        else if (r >= 1)
            x = x > 0 ? (x - 1) % size : size - 1

        r = getR(seed, i + 1)
        if (r >= 4)
            y = (y + 1) % size
        else if (r >= 1)
            y = y > 0 ? (y - 1) % size : size - 1
        setPixel(data, x, y, size)
    }

    ctx.putImageData(imgData, 0, 0);
    return canvas.toDataURL();

    // var scale = 10
    // var canvas2 = document.createElement("canvas");
    // canvas2.width = size * scale;
    // canvas2.height = size * scale;
    // var ctx2 = canvas2.getContext("2d");
    // ctx2.imageSmoothingEnabled = false;
    // ctx2.drawImage(canvas, 0, 0, size * scale, size * scale);

    // return canvas2.toDataURL();
}


export const addOffsets = function (seed, offsets, count) {
    var cnt = offsets.length + count
    for (var i = offsets.length; i < cnt; i += 1) {
        var o_x = 0
        var o_y = 0
        var r = getR(seed, i * 2 + 2)
        if (r >= 4)
            o_x = 1
        else if (r >= 1)
            o_x = -1

        r = getR(seed, i * 2 + 1 + 2)
        if (r >= 4)
            o_y = 1
        else if (r >= 1)
            o_y = -1
        offsets.push([o_x, o_y])
    }

    return offsets
}


function getSize(numCells) {
    return Math.max(Math.floor(Math.sqrt(Math.floor(numCells / 7 * 10)) / 2) * 2, 8)
}

export const drawWithOffsets = function (offsets) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    var numCells = offsets.length * 2 + 2
    var size = getSize(numCells)

    canvas.width = size;
    canvas.height = size;

    var imgData = ctx.getImageData(0, 0, size, size);
    var data = imgData.data;

    var x = Math.floor(size / 2)
    var y = x

    setPixel(data, x, y, size)

    for (var i = 0; i < offsets.length; i += 1) {
        var o_x = offsets[i][0]
        var o_y = offsets[i][1]
        if (o_x == 1)
            x = (x + 1) % size
        else if (o_x == -1)
            x = x > 0 ? (x - 1) % size : size - 1

        if (o_y == 1)
            y = (y + 1) % size
        else if (o_y == -1)
            y = y > 0 ? (y - 1) % size : size - 1
        setPixel(data, x, y, size)
    }

    ctx.putImageData(imgData, 0, 0);
    return canvas.toDataURL();
}