<template>
  <div class="wallet">
    <button type="button" @click="connectWallet">
      {{
        this.$store.state.wallet.connected
          ? this.$store.state.wallet.address.substring(0, 10)
          : "Connect Wallet"
      }}
    </button>
    <div class="err" v-show="showError">{{ this.errorMsg }}</div>
  </div>
</template>

<script>
import {
  getWallet,
  getContract,
  CHAIN_ID,
  getSyringeContract,
} from "../wallet";
import { markRaw } from "vue";

export default {
  name: "Wallet",
  props: {},
  data() {
    return {
      chainId: null,
      showError: false,
      errorMsg: "",
    };
  },
  watch: {
    chainId: function (value) {
      this.$store.state.wallet.chainId = value;
      this.$store.state.wallet.connected = value == CHAIN_ID;
      this.showError = value != CHAIN_ID;
    },
  },
  methods: {
    connectWallet() {
      const signer = getWallet();
      if (!signer) {
        this.errorMsg = "Please install MetaMask";
        this.chainId = null;
        return;
      }
      this.$store.state.wallet.signer = signer;
      const contract = markRaw(getContract(signer));
      this.$store.state.wallet.contract = contract;
      const syringeContract = markRaw(getSyringeContract(signer));
      this.$store.state.wallet.syringeContract = syringeContract;
      signer.getAddress().then((addr) => {
        this.$store.state.wallet.address = addr;
        signer.getChainId().then((chainId) => {
          this.chainId = chainId;
          if (chainId != CHAIN_ID) {
            this.errorMsg = "Please switch to the Ethereum Mainnet";
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.err {
  margin: 40px;
}
</style>
