<template>
  <notifications />
  <h1>Etholvants</h1>
  <h3>Evolving. On Chain. Forever.</h3>
  <div class="rlink-cont">
    <li class="rlink"><router-link to="/">Home</router-link></li>
    <li class="rlink"><router-link to="/explore">Explore</router-link></li>
  </div>
  <hr />
  
  <div class="main">
    <router-view v-slot="{ Component }">
      <keep-alive>
          <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.rlink {
  margin-right: 10px;
  margin-top: 20px;
}

body {
  width: 60%;
  margin: auto;
}

.info {
  text-align: left;
  margin-top: 40px;
}

figure img {
  width: 100px;
  image-rendering: pixelated;
  vertical-align: top;
  padding: 12px;
}

figure {
  display: inline-block;
  margin: auto;
  padding: 20px;
}

figure figcaption {
  text-align: center;
  font-size: 14px;
}

h3 {
  margin: 40px 0 0;
}

h4 {
  margin-top: 40px;
}

h1 {
  margin-bottom: -20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #838383;
}

.router-link-active {
  color: #292727;
}

.cent-cont {
  width: 90%;
  margin: auto;
  text-align: center;
}

.rlink-cont {
  margin-top: 10px;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  margin-top: 40px;
  margin-bottom: 40px;
}

button {
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  background: #131313;
  transition: all 0.2s;
}

button:hover {
  color: #000000;
  background-color: #d1d1d1;
  cursor: pointer;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #e0e0e0;
  color: #838383;
  cursor: not-allowed;
}

table {
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
  font-size: 14px;
  border-collapse: collapse;
  border-radius: 10px;
}

td {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
</style>
