import { ethers } from "ethers";
import ABI from './assets/abi'
import SYRINGE_ABI from './assets/syringe_abi'

export const CHAIN_ID = 1 // Mainnet
const CONTRACT_ADDRESS = '0x1fFF1e9e963f07AC4486503E5a35e71f4e9Fb9FD' // Mainnet
const SYRINGE_CONTRACT = '0xA6310c39FAA5E21cC29c4B283C6CD4eF51c00D21' // Mainnet

// export const CHAIN_ID = 1337 // local
// const CONTRACT_ADDRESS = '0x3194cBDC3dbcd3E11a07892e7bA5c3394048Cc87' // local
// const SYRINGE_CONTRACT = '0x602C71e4DAC47a042Ee7f46E0aee17F94A3bA0B6' // local

// export const CHAIN_ID = 4 // local
// const CONTRACT_ADDRESS = '0xbc97b4744e0cb98d8cd20f46738334dc5e4d1f97' // rink
// const SYRINGE_CONTRACT = '0x031fA9fca401C669F87fccbb8AbfeA3aA24f8C9B' // rink

export const getWallet = function () {
    if (window.ethereum) {
        console.log('Ethereum successfully detected!')
        window.ethereum.request({
            method: 'eth_requestAccounts'
        });
    } else {
        console.error('Please install MetaMask!')
        return
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner()
    return signer
}

export const getContract = function (signer) {
    return new ethers.Contract(CONTRACT_ADDRESS, ABI, signer);
}

export const getSyringeContract = function (signer) {
    return new ethers.Contract(SYRINGE_CONTRACT, SYRINGE_ABI, signer);
}
