<template>
  <div class="home">
    <Info />
    <hr />
    <Wallet />
    <Mint />
    <h4>External Links</h4>
    <ul class="links">
      <li>
        <a href="https://twitter.com/etholvants" target="_blank" rel="noopener"
          >Twitter</a
        >
      </li>
      <li>
        <a href="https://discord.gg/3BpZzQkqt7" target="_blank" rel="noopener"
          >Discord</a
        >
      </li>
      <li>
        <a
          href="https://opensea.io/collection/etholvants"
          target="_blank"
          rel="noopener"
          >OpenSea</a
        >
      </li>
      <li>
        <a
          href="https://etherscan.io/address/0x1fff1e9e963f07ac4486503e5a35e71f4e9fb9fd"
          target="_blank"
          rel="noopener"
          >Etherscan</a
        >
      </li>
      <li>
        <a
          href="https://etherscan.io/address/0x9cc9ce9e611424b97b0ca343294bde8ed2a2ec51"
          target="_blank"
          rel="noopener"
          >SVG Exporter Contract</a
        >
      </li>
      <li>
        <a
          href="https://opensea.io/collection/etholvants-booster-syringe"
          target="_blank"
          rel="noopener"
          >Syringe OpenSea</a
        >
      </li>
      <li>
        <a
          href="https://etherscan.io/address/0xA6310c39FAA5E21cC29c4B283C6CD4eF51c00D21"
          target="_blank"
          rel="noopener"
          >Syringe Contract</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import Info from "./Info.vue";
import Wallet from "./Wallet.vue";
import Mint from "./Mint.vue";

export default {
  name: "Home",
  props: {},
  components: {
    Info,
    Wallet,
    Mint,
  },
};
</script>

<style >
.links {
  font-size: 14px;
}
</style>
