<template>
  <div class="info">
    <div>
      <p>
        Etholvants are 100% on-chain generated, deflationary and evolving
        creatures living on the Ethereum blockchain.
      </p>

      <p>
        Rules are simple. There are 10,000 unique etholvants available to mint
        at a price of 0.024 ETH each. They will be born with 24 cells initially
        and they can evolve by either staking or combining.
      </p>

      <p>
        If you stake an etholvant into the contract, it will grow by 2 cells in
        every 4 hours. So if you stake one with 24 cells, after one day it will
        have 36 cells.
      </p>

      <p>
        If you combine multiple etholvants, they will be burnt and a new one
        will be created. Its number of cells will be equal to the sum of the
        number of cells of the burnt ones. So if you combine two etholvants with
        24 cells and 40 cells, you’ll get one with 64 cells.
      </p>

      <p>
        Combining and staking are free (only gas fees) and unstaking has a fee
        of 0.01 ETH per transaction (you can unstake multiple tokens in each
        transaction). The size of the art will also increase as the etholvant
        evolves.
      </p>

      <p>
        Everything is on-chain, including the art, which you can get the
        positions of the cells from the contract. Etholvants are standard ERC721
        tokens so you can buy and sell them on OpenSea.
      </p>

      <p>
        Below you can see a sample etholvant evolving through different stages
        of its life:
      </p>
      <div class="cent-cont">
        <figure>
          <img src="../assets/24.png" />
          <figcaption>24 cells</figcaption>
        </figure>
        <figure>
          <img src="../assets/50.png" />
          <figcaption>50 cells</figcaption>
        </figure>
        <figure>
          <img src="../assets/100.png" />
          <figcaption>100 cells</figcaption>
        </figure>
        <figure>
          <img src="../assets/500.png" />
          <figcaption>500 cells</figcaption>
        </figure>
        <figure>
          <img src="../assets/1000.png" />
          <figcaption>1000 cells</figcaption>
        </figure>
        <figure>
          <img src="../assets/5000.png" />
          <figcaption>5000 cells</figcaption>
        </figure>
        <figure>
          <img src="../assets/10000.png" />
          <figcaption>10000 cells</figcaption>
        </figure>
      </div>
      <p>
        <a @click.stop="showSyrInfo = !showSyrInfo" href="javascript:void(0);"
          >What are Booster Syringes?</a
        >
      </p>
      <div v-show="showSyrInfo">
        <p>
          Each Etholvant can be eligible to mint 1 syringe pre-filled with the
          booster. (mints are free)
        </p>
        <p>
          Requirements for the etholvant to be eligible to mint the booster
          syringe: <br />
          1. Should have at least 1440 cells.<br />
          2. Should be staked and should’ve been staked from at least 24 days
          prior the time of minting the syringe. If you unstake, the staking
          time will be reset.
        </p>
        <p>
          These requirements are only for minting the syringe, not for using
          it.<br />
          They can be used on any eligible-to-inject ethol. <br />
        </p>
        <p>
          Notes: <br />
          - Each etholvant can only be used once to mint a booster syringe.<br />
          - When you inject the booster into an etholvant, its growth rate will
          be increased by 2 cells per 4 hours and the syringe will be burned.
          <br />
          - Each etholvant can be injected only once.<br />
          - Etholvants should be staked for minting the syringe and should be
          unstaked for injection.<br />
          - Maximum allowed growth rate for an etholvant is 8 cells per 4
          hours.<br />
          - If you combine multiple etholvants, the resulting one will have the
          maximum (not sum) growth rate of the combined ones. <br />
          - Etholvants created by combining are new tokens and are eligible to
          mint/inject if they have the requirements above. <br />
          - Contract will allow you to mint the syringe until June 1, 2022, but
          you can use the minted syringes whenever you want. <br />
          - Syringes are standard ERC1155 tokens and can be traded.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: {},
  data() {
    return {
      showSyrInfo: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
