import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import Notifications from '@kyvg/vue3-notification'

import { createStore } from "vuex"

import Explore from "./components/explore.vue"
import Home from "./components/Home.vue"

const store = createStore({
    state: {
        wallet: {
            signer: null,
            contract: null,
            syringeContract: null,
            address: null,
            chainId: null,
            connected: null,
        }
    }
})


const router = new createRouter({
    history: createWebHashHistory(),
    routes: [
        { path: '/', component: Home },
        { path: '/explore', component: Explore },
    ]
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Notifications)
app.mount('#app')
